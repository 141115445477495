import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SideNavigationComponent } from "./side-navigation/side-navigation.component";
import { SideNavigationItemComponent } from "./side-navigation-item/side-navigation-item.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    SideNavigationComponent
  ],
  declarations: [SideNavigationComponent,
    SideNavigationItemComponent]
})
export class SideNavigationModule { }
