import { ExamTypeModel } from "../models/exam-type.model";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { ExamService } from "./exam.service";
import { Observable } from "rxjs";

@Injectable()
export class ExamTypesResolver implements Resolve<ExamTypeModel[]> {
    constructor(private examService: ExamService) {}
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExamTypeModel[]> {
        return this.examService.getPossibleExams();
    }
}
