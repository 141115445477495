import {Injectable} from "@angular/core";
import {Exam, ExamIdType} from "../../models/exam.model";
import {Patient} from "../../models/patient.model";
import {Observable} from "rxjs";
import {BackendService} from "../../services/backend.service";
import {ExamCardModel} from "../../models/exam-card.model";
import {ExamTypeModel} from "../../models/exam-type.model";
import {map} from "rxjs/operators";

@Injectable()
export class ActiveExamsService {

  constructor(private http: BackendService) {
  }

  public getActiveExam(id: number): Observable<Exam> {
    return this.http.get("/api/exam/" + id)
      .pipe(map((exam: Exam) => this.deserializeExamData(exam)));
  }

  public getActiveExamCards(filter?: string): Observable<ExamCardModel[]> {
    if (filter == null || filter.length === 0) {
      return this.http.get("/api/examCard?onlyClosed=1");
    } else {
      return this.http.get("/api/examCard?onlyClosed=1&filter=" + encodeURIComponent(filter));
    }
  }

  public saveActiveExam(exam: Exam): Observable<Exam> {
    exam.examCardFile = JSON.stringify(exam.examData);
    return this.http.patch<Exam>("/api/exam/" + exam.id, exam)
      .pipe(map((updatedExam: Exam) => this.deserializeExamData(updatedExam)));
  }

  public createNewExam(examTypeId: ExamTypeModel, patient: Patient): Observable<Exam> {
    const examModel = new Exam();
    examModel.examTypeId = examTypeId.id;
    examModel.patient = patient;
    return this.http.post("/api/exam", examModel);
  }

  public removeExam(examId: ExamIdType): Observable<any> {
    return this.http.delete("/api/exam/" + examId);
  }

  public closeExam(id: number): Observable<any> {
    return this.http.post("/api/exam/" + id + "/close", {});
  }

  private deserializeExamData(examFromServer: Exam): Exam {
    if (examFromServer.examCardFile != null && examFromServer.examCardFile.length > 0) {
      try {
        examFromServer.examData = JSON.parse(examFromServer.examCardFile);
      } catch (e) {
        examFromServer.examData = {};
      }
    } else {
      examFromServer.examData = {};
    }
    return examFromServer;
  }
}
