
import { Injectable } from "@angular/core";
import { Exam } from "../models/exam.model";
import {Observable, of} from "rxjs";
import { BackendService } from "./backend.service";


export class ExamList {
  exams: Exam[];
}

@Injectable()
export class ClosedExamsService {

  constructor(private backendService: BackendService) { }

  public getClosedExams(): Observable<ExamList> {
    return this.backendService.get("/api/exam", {params: {settled: "0"}});
  }
}
