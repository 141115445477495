import {NgModule, ModuleWithProviders} from "@angular/core";
import {CommonModule} from "@angular/common";
import { NgFullLoaderComponent} from "./loader.component";
import {NgFullLoaderService} from "./loader.service";
import {ILoaderConfig, LOADER_GLOBAL_CONFIG} from "./LoaderConfig";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {LoaderInterceptor} from "./loader.interceptor";

const LOADER_DEFAULT_CONFIG: ILoaderConfig = {
    enableForHttp: true,
    enableForRouting: false,
    defaultDelay: 500
  };

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NgFullLoaderComponent
  ],
  exports: [
    NgFullLoaderComponent
  ],
  providers: [
    NgFullLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    }
  ]
})
export class NgFullLoaderModule {
  public static forRoot(options?: ILoaderConfig): ModuleWithProviders {
    return {
      ngModule: NgFullLoaderModule,
      providers: [
        {
          provide: LOADER_GLOBAL_CONFIG,
          useValue: options ? Object.assign(LOADER_DEFAULT_CONFIG, options) : LOADER_DEFAULT_CONFIG
        },
      ],
    };

  }
}
