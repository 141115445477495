import { Component, Input, OnInit } from "@angular/core";
import { NavigationItem } from "../models/navigation-item.model";
import { Router } from "@angular/router";

@Component({
  selector: "side-navigation-item",
  templateUrl: "./side-navigation-item.component.html",
  styleUrls: ["./side-navigation-item.component.scss"]
})
export class SideNavigationItemComponent {
  @Input() public menuItems: NavigationItem;
  @Input() public level = 1;

  constructor(public router: Router) {
  }

}
