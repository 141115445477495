import {BehaviorSubject, Observable, throwError as observableThrowError} from "rxjs";
import {filter, switchMap, take, tap} from "rxjs/operators";
import {Injectable, Injector} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {AuthService} from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private injector: Injector) {
    setTimeout(() => {
      this.initializeAuthService();
    });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authService) {
      this.initializeAuthService();
    }
    if (req.url.indexOf(this.authService.authEndpoint) !== -1) {
      return next.handle(req);
    }
    return next.handle(this.addToken(req)).pipe(
      tap(
        () => {
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 400:
                return this.handle400Error(error);
              case 401:
                return this.handle401Error(req, next);
            }
          }
        }));
  }

  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    if (this.authService.authToken != null) {
      return req.clone({headers: req.headers.set("Authorization", `Bearer ${this.authService.authToken}`)});
    } else {
      return req;
    }
  }

  private handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.error === "invalid_grant") {
      return this.logoutUser();
    }
    return observableThrowError(error);
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      this.authService.processRefreshToken();
      return this.authService.$tokenFetched.pipe(
        switchMap((tokenFeched: boolean) => {
          this.isRefreshingToken = false;
          if (tokenFeched) {
            this.tokenSubject.next(tokenFeched);
            return next.handle(this.addToken(req));
          } else {
            return this.logoutUser();
          }
        }));
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req));
        }));
    }
  }

  private logoutUser() {
    this.authService.logout();
    return observableThrowError("");
  }

  private initializeAuthService() {
    this.authService = this.injector.get(AuthService);
  }
}
