import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PracticeRoutingModule} from "./practice-routing.module";
import {PracticeDetailsViewComponent} from "./practice-details-view/practice-details-view.component";
import {SharedModule} from "../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {PracticeResolver} from "./practice.resolver";
import {CurrentPracticeService} from "../services/current-practice.service";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    PracticeRoutingModule
  ],
  declarations: [PracticeDetailsViewComponent],
  exports: [PracticeDetailsViewComponent],
  entryComponents: [PracticeDetailsViewComponent],
  providers: [PracticeResolver, CurrentPracticeService]
})
export class PracticeModule {
}
