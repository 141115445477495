import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {AuthService} from "./auth.service";

@Injectable()
export class IsLoggedCanActivateGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  public canActivate(): boolean {
    if (!this.authService.isLogged) {
      this.router.navigate(["/logging"]);
      return false;
    }
    return true;
  }
}
