import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UserAddComponent} from "./user-add/user-add.component";
import {UsersListComponent} from "./users-list/users-list.component";
import {UsersRoutingModule} from "./users-routing.module";
import {SharedModule} from "../shared/shared.module";
import {UserDetailsProvider} from "./providers/user-details.provider";
import {ReactiveFormsModule} from "@angular/forms";
import { CurrentUserDetailsComponent } from "./current-user-details/current-user-details.component";
import {CheckForPendingModificationsGuard} from "../active-exam/guards/check-for-pending-modifications.guard";
import {MatDialogModule, MatMenuModule} from "@angular/material";
import { UsersListItemComponent } from "./users-list-item/users-list-item.component";
import {UsersProvider} from "./providers/users.provider";

@NgModule({
  imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatMenuModule
  ],
  declarations: [
    UserAddComponent,
    UsersListComponent,
    CurrentUserDetailsComponent,
    UsersListItemComponent
  ],
  providers: [UserDetailsProvider, CheckForPendingModificationsGuard, UsersProvider],
  entryComponents: [CurrentUserDetailsComponent]
})
export class UsersModule {
}
