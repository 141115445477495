import { Directive, HostBinding, Input, HostListener } from "@angular/core";

export const ACCEPT_ALL_ZONE_NAME = "accept.all";
export const ZONE_NAME_DATA = "text/plzone";

@Directive({
  selector: "[plDraggable]"
})
export class DraggableDirective {
  @HostBinding("draggable")
  get draggable() {
    return true;
  }
  @Input()
  set plDraggable(zone: string) {
    if (zone) {
      this._zone = zone;
    } else {
      this._zone = ACCEPT_ALL_ZONE_NAME;
    }
  }

  @Input()
  set plDragModel(data: any) {
    if (data) {
      this._data = data;
    } else {
      this._data = null;
    }
  }

  constructor() { }

  @HostListener("dragstart", ["$event"])
  public onDragStart(event) {
    event.dataTransfer.setData(this._zone, ZONE_NAME_DATA);
    if (this._data) {
      event.dataTransfer.setData("application/json", JSON.stringify(this._data));
    }
  }

  private _data: any;
  private _zone: string;
}
