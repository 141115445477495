import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MatTabsModule} from "@angular/material/tabs";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule
} from "@angular/material";
import {MatSnackBarModule} from "@angular/material";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {FormsModule} from "@angular/forms";
import {MatRadioModule} from "@angular/material/radio";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DragModule} from "../directives/drag.module";
import {IfGrantedDirective} from "./if-granted.directive";

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    IfGrantedDirective
  ],
  entryComponents: [ConfirmDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatChipsModule,
    MatRadioModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    DragModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatSnackBarModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatChipsModule,
    MatRadioModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatTableModule,
    MatIconModule,
    ConfirmDialogComponent,
    MatTooltipModule,
    DragModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatCheckboxModule,
    MatSnackBarModule,
    IfGrantedDirective
  ]
})
export class SharedModule {
}
