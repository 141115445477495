export enum AccessLevel {
    User = 1,
    Admin = 2
}

export class User {
    public username?: string;
    public password?: string;
    public firstName: string;
    public lastName: string;
    public nrUprawnienia: string;
    public organWydajacyUprawnienie: string;
    public accessLevel?: AccessLevel;
}
