import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Patient} from "../models/patient.model";
import {BackendService} from "./backend.service";
import {LizardBackendService} from "./lizard-backend.service";
import {NgFullLoaderService} from "../core/loader/loader.service";
import {tap} from "rxjs/operators";

@Injectable()
export class KnownPatientsService {

  public patients: Patient[] = [];

  constructor(private backendService: BackendService, private lizardBackendService: LizardBackendService,
              private loaderService: NgFullLoaderService) {
  }

  public findPatientsByPesel(peselNr: string): void {
    if (peselNr == null || peselNr.length === 0) {
      this.patients = [];
      return;
    }
    this.getKnownPatientsByPesel(peselNr)
      .subscribe((x: Patient[]) => this.handleNewPatients(x, peselNr));
  }

  public getKnownPatientsByPesel(peselNumber: string): Observable<Patient[]> {
    this.loaderService.disableHttpLoader();
    return this.backendService.get<Patient[]>("/api/patient", {params: {size: "10", page: "0", peselNr: peselNumber}})
      .pipe(tap(() => this.loaderService.enableHttpLoader(),
        () => this.loaderService.enableHttpLoader()));
  }

  public getKnownPatientsByPeselFromLizard(peselNumber: string, count = 10): Observable<Patient[]> {
    return this.lizardBackendService.get<Patient[]>("/api/users", {params: {size: count.toString(), page: "0", peselNr: peselNumber}});
  }

  private handleNewPatients(x: Patient[], peselNRFilter: string): void {
    this.patients = this.distintByPesel(x);
    if (this.patients.length < 10) {
      this.loaderService.disableHttpLoader();
      this.getKnownPatientsByPeselFromLizard(peselNRFilter)
        .pipe(tap((patients: Patient[]) => this.removeDrivingLicensesFromPatients(patients)))
        .subscribe(
          (patients: Patient[]) => this.handleNewPatientsFromLizard(patients),
          () => this.loaderService.enableHttpLoader()
        );
    }
  }

  private removeDrivingLicensesFromPatients(patients: Patient[]) {
    for (const patient of patients) {
      patient.drivingLicenceCats = null;
    }
  }

  private handleNewPatientsFromLizard(x: Patient[]): void {
    this.loaderService.enableHttpLoader();
    const patients2Filter = this.patients;
    patients2Filter.splice(this.patients.length, 0, ...x);
    this.patients = this.distintByPesel(patients2Filter);
  }

  private distintByPesel(patients: Patient[]): Patient[] {
    const filteredPatients: Patient[] = [];
    const peselSet: { [key: string]: any } = {};
    for (const patient of patients) {
      if (peselSet[patient.peselNumber] != null) {
        continue;
      }

      peselSet[patient.peselNumber] = true;
      filteredPatients.push(patient);
    }

    return filteredPatients;
  }
}
