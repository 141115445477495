import {Component, OnInit} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {FormControl} from "@angular/forms";
import {IPractice} from "../../models/practice.model";
import {BackendService} from "../../services/backend.service";
import {MatDialogRef} from "@angular/material";
import {CurrentPracticeService} from "../../services/current-practice.service";
import {AuthService} from "../../core/logging/auth.service";

@Component({
  selector: "details-view",
  templateUrl: "./practice-details-view.component.html",
  styleUrls: ["./practice-details-view.component.scss"]
})
export class PracticeDetailsViewComponent implements OnInit {

  public practiceGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<PracticeDetailsViewComponent>,
              private currentPractiveService: CurrentPracticeService,
              private backendService: BackendService,
              private authService: AuthService) {
    this.createPracticeGroup();
  }

  public ngOnInit() {
    this.currentPractiveService.getCurrentPractice().subscribe((practice: IPractice) => {
      this.updateModel(practice);
    });
  }

  public save(): void {
    const practiceNameChanged = this.practiceGroup.get("name").dirty;
    this.backendService.patch("/api/practice/current", this.practiceGroup.getRawValue())
      .subscribe(() => {
        this.dialogRef.close();
        if (practiceNameChanged) {
          this.authService.logout();
        }
      });
  }

  private updateModel(practice: IPractice): void {
    this.practiceGroup.patchValue(practice as any);
  }

  private createPracticeGroup(): void {
    this.practiceGroup = new FormGroup({
      "nip": new FormControl(),
      "name": new FormControl(),
      "nrPracowni": new FormControl(),
      "wydanePrzez": new FormControl(),
      "town": new FormControl(),
      "postalCode": new FormControl(),
      "homeNumber": new FormControl(),
      "flatNumber": new FormControl(),
      "street": new FormControl()
    });
  }

}
