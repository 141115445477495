import { Injectable } from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable()
export class ConfigurationService {

  constructor() { }

  public getServerUrl(): string {
    return environment.url;
  }

  public getLizardServerUrl(): string {
    return environment.lizardUrl;
  }
}
