export const environment = {
  production: true,
  url: "https://beta-api.polcom-psycho.pl",
  lizardUrl: "https://lizard-api.polcom-psycho.pl",
  dateFormat: "dd/MM/yyyy",
  auth: {
    client: "psychoLabClient",
    secret: "secret"
  }
};
