import {HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {LoginModel} from "./models/LoginModel";
import {HostListener} from "@angular/core";

export abstract class AuthHelperAbstract {
  public authEndpoint = "oauth/token";
  protected url = `${environment.url}/${this.authEndpoint}`;
  protected sessionStorageKey = "auth_token";

  @HostListener("window:onbeforeunload", ["$event"])
  private clearLocalStorage(event) {
    localStorage.removeItem(this.sessionStorageKey);
    localStorage.clear();
  }

  protected getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", `Basic ${btoa(`${environment.auth.client}:${environment.auth.secret}`)}`);
  }

  protected getTokenUrl(login: LoginModel): string {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("grant_type", "password");
    urlSearchParams.append("username", login.login);
    urlSearchParams.append("password", login.password);
    return urlSearchParams.toString();
  }
  protected getRefreshTokenUrl(refreshToken: string): string {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("grant_type", "refresh_token");
    urlSearchParams.append("refresh_token", refreshToken);
    return urlSearchParams.toString();
  }

}
