import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Observable } from "rxjs";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { ConfirmDialogType, IConfirmDialogData, ConfirmDialogResult } from "./confirm-dialog.model";

@Injectable()
export class ConfirmDialogService {
public dialogType = ConfirmDialogType.NoYes;
  constructor(public dialog: MatDialog) { }

  public showConfirmDialog(dailogData: IConfirmDialogData): Observable<ConfirmDialogResult> {
    return this.dialog.open(ConfirmDialogComponent, { data: dailogData }).afterClosed();
  }
}
