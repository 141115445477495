import {Patient} from "./patient.model";
import {ExamGuidType} from "./exam-id.model";

export type ExamIdType = number;

export interface IWorkpiece {
  id?: number;
  name: string;
}

export interface ITargetModel {
  id: number;
  name?: string;
}

export class Exam {
  id: ExamIdType;
  examNr?: string;
  patient: Patient;
  closed?: boolean;
  settled?: boolean;
  modified?: boolean;
  examTypeId?: ExamGuidType;
  examDate: Date;
  lastExamDate: Date;
  examExpireDate: Date;
  comments: string;
  workplaces: IWorkpiece[];
  workplaceName: string;
  branch: string;
  stempNr: string;
  examResultPossitive: boolean;
  daysOfContraindications: number;
  contraindicationsComments: string;
  targets: ITargetModel[];
  examCardFile: string;
  examData: {[key: string]: any};
}
