export interface IConfirmDialogData {
  title: string;
  message: string;
  dialogType?: ConfirmDialogType;
}

export enum ConfirmDialogType {
  NoYes = "noYes",
  YesNoCancel = "yesNoCancel",
  Ok = "ok"
}

export enum ConfirmDialogResult {
  yes,
  no,
  cancel
}
