import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {User} from "../models/user.model";
import {BackendService} from "./backend.service";

@Injectable()
export class CurrentUserService {
  constructor(private backendService: BackendService) {}
  public getCurrentUser(): Observable<User> {
    return this.backendService.get<User>("/api/user/my");
  }

  public getUser(userLogin: any): Observable<User> {
    return this.backendService.get<User>("/api/user/" + userLogin);
  }
}
