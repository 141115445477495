import { Injectable } from "@angular/core";
import { Patient } from "../models/patient.model";

@Injectable()
export class PatientNameService {

  constructor() { }

  public getPatientFullName(patient: Patient): string {
    if (patient == null) {
      return "";
    }

    return patient.firstName + " " + patient.lastName;
  }
}
