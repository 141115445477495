import { Directive } from "@angular/core";
import {ViewContainerRef} from "@angular/core";
import {ElementRef} from "@angular/core";
import {TemplateRef} from "@angular/core";
import {Input} from "@angular/core";
import {AuthService} from "../core/logging/auth.service";

@Directive({
  selector: "[ifGranted]"
})
export class IfGrantedDirective {

  @Input()
  public set ifGranted(role: string | string[]) {
    if (Array.isArray(role)) {
      this.requiredRoles = role;
    } else if (role != null) {
      this.requiredRoles = [role];
    } else {
      this.requiredRoles = [];
    }

    this.updateView();
  }

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {
  }

  private updateView() {
    if (this.checkIfUserHasPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkIfUserHasPermission(): boolean {
    for (const requiredRole of this.requiredRoles) {
      if (!this.authService.hasRole(requiredRole)) {
        return false;
      }
    }
    return true;
  }

  private requiredRoles: string[] = [];
}
