import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material";

@Injectable({
  providedIn: "root"
})
export class MessageBubbleService {

  constructor(private snackBar: MatSnackBar) {
  }

  public openError(message: string): void {
    this.snackBar.open(message, "", {duration: 2500});
  }
}
