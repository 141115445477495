import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {User} from "../../models/user.model";
import {BackendService} from "../../services/backend.service";
import {MatDialog} from "@angular/material";
import {UserAddComponent} from "../user-add/user-add.component";

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"]
})
export class UsersListComponent {

  public users: User[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private backendService: BackendService,
              private dialog: MatDialog) {
    this.activatedRoute.data.subscribe(data => {
      this.users = data["users"];
    });
  }

  public addUser(): void {
    this.dialog.open(UserAddComponent);
  }

  public updateData(): void {
    this.backendService.get<User[]>("/api/users")
      .subscribe((data) => {
        this.users = data;
      });
  }
}
