import {Component, Input, OnInit} from "@angular/core";
import {NavigationItem} from "../models/navigation-item.model";

@Component({
  selector: "side-navigation",
  templateUrl: "./side-navigation.component.html",
  styleUrls: ["./side-navigation.component.scss"]
})
export class SideNavigationComponent {
  @Input() public menuItems: NavigationItem;

  constructor() {
  }
}
