import {Component, Inject} from "@angular/core";
import {AccessLevel, User} from "../../models/user.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../settings/services/users.service";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../core/logging/auth.service";
import {CurrentUserService} from "../../services/current-user.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {PasswordService} from "../../core/logging/password.service";
import {ValidatorsHelper} from "../../services/validators.helper";

@Component({
  selector: "current-user-details",
  templateUrl: "./current-user-details.component.html",
  styleUrls: ["./current-user-details.component.scss"]

})
export class CurrentUserDetailsComponent {

  public user: User;
  public passwordConfirm: string;
  public userFormGroup: FormGroup;

  constructor(private userService: UsersService,
              private currentUserService: CurrentUserService,
              private activatedRoute: ActivatedRoute,
              private dialogRef: MatDialogRef<CurrentUserDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public userData: any,
              private authService: AuthService,
              private passwordService: PasswordService) {
    this.createFormGroup();
    this.currentUserService.getUser(userData.username).subscribe(data => {
      this.updateModel(data);
    });
  }

  public updateUser(): void {
    if (this.isComponentModified()) {
      const userModel = this.readModel();
      this.userService.updateUser(userModel, true).subscribe(
        () => {
          this.dialogRef.close();
          if (userModel.username === this.authService.currentUser.userName) {
            this.authService.logout();
          }
        }
      );
    } else {
      this.dialogRef.close();
    }
  }

  public isComponentModified(): boolean {
    return this.userFormGroup.dirty;
  }

  public restartPassword(): void {
    this.passwordService.resetPassword("")
      .subscribe();
  }

  private readModel(): User {
    const userData = this.userFormGroup.getRawValue();
    userData.accessLevel = userData.roles.doctorAccessLevel ? AccessLevel.User : 0;
    if (userData.roles.adminAccessLevel === true) {
      userData.accessLevel += AccessLevel.Admin;
    }
    delete userData.roles;
    return userData;
  }

  private updateModel(userData: User): void {
    userData["roles"] = {
      "adminAccessLevel": this.isAdmin(userData),
      "doctorAccessLevel": this.isUser(userData)
    };
    this.userFormGroup.patchValue(userData);
  }

  private isAdmin(userData: User): boolean {
    // tslint:disable-next-line:no-bitwise
    return (userData.accessLevel & AccessLevel.Admin) === AccessLevel.Admin;
  }

  private isUser(userData: User): boolean {
    // tslint:disable-next-line:no-bitwise
    return (userData.accessLevel & AccessLevel.User) === AccessLevel.User;
  }

  private createFormGroup(): void {
    const userNameFormControl = new FormControl();
    userNameFormControl.disable();
    const doctorAccessLevel = new FormControl();
    doctorAccessLevel.disable();
    const adminAccessLevel = new FormControl();
    if (!this.authService.currentUser.isAdmin) {
      adminAccessLevel.disable();
    }
    this.userFormGroup = new FormGroup({
      "username": userNameFormControl,
      "email": new FormControl("", Validators.required),
      "firstName": new FormControl(),
      "lastName": new FormControl(),
      "nrUprawnienia": new FormControl(),
      "organWydajacyUprawnienie": new FormControl(),
      "roles": new FormGroup({
        "adminAccessLevel": new FormControl(),
        "doctorAccessLevel": new FormControl(true)
      }, ValidatorsHelper.validateUserRoles)
    });
  }
}
