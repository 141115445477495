import {FormControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export class ValidatorsHelper {
  public static validateUserRoles(fieldGroup: FormGroup): ValidationErrors {
    if (!fieldGroup.get("adminAccessLevel").value &&
      !fieldGroup.get("doctorAccessLevel").value) {
      return {
        NoRole: true
      };
    }

    return null;
  }

  public static validatePasswordRepeated(passwordFormControl: FormControl): ValidatorFn {
    return (fieldControl: FormControl) => {
      if (!passwordFormControl.touched) {
        return null;
      }
      return fieldControl.value === passwordFormControl.value ? null : {
        NotEqual: true
      };
    };
  }

}
