import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

export interface IModifiableComponent {
  isComponentModified(): boolean;

  updateModel(): void;
}

@Injectable({
  providedIn: 'root'
})
export class ModificationCheckService implements IModifiableComponent {
  public saveDataRequest = new Subject<any>();
  public dataSaved = new Subject();

  constructor() {
  }

  public isComponentModified(): boolean {
    for (const component of this.components) {
      if (component.isComponentModified()) {
        return true;
      }
    }

    return false;
  }

  public updateModel(): void {
    for (const component of this.components) {
      component.updateModel();
    }
  }

  public registerModifiableComponent(component: IModifiableComponent): void {
    if (this.components.indexOf(component) !== -1) {
      return;
    }

    this.components.push(component);
  }

  public unregisterModifiableComponent(component: IModifiableComponent): void {
    const componentIndex = this.components.indexOf(component);
    if (componentIndex === -1) {
      return;
    }

    this.components.splice(componentIndex, 1);
  }

  private components: IModifiableComponent[] = [];
}
