import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Exam } from "../models/exam.model";
import { ExamService, EXAM_ID_PARAM_NAME } from "./exam.service";
import { Observable } from "rxjs";
import {ActiveExamsService} from "../active-exam/services/active-exams.service";

@Injectable()
export class ExamResolver implements Resolve<Exam> {
  constructor(private activeExamService: ActiveExamsService) { }
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Exam> {
    const examId = parseInt(route.paramMap.get(EXAM_ID_PARAM_NAME));
    return this.activeExamService.getActiveExam(examId);
  }
}
