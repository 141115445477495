import {Component, HostBinding} from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  @HostBinding("class") componentCssClass;

  constructor() {
    this.componentCssClass = "black-theme";
  }
}
