export class PeselService {
  public getBirthdayFromPesel(peselNumber: string): Date {
    const birtday = new Date();
    let year = parseInt(peselNumber.slice(0, 2));
    let month = parseInt(peselNumber.slice(2, 4));
    const day = parseInt(peselNumber.slice(4, 6));

    if (month > 12) {
      month -= 20;
      year += 2000;
    } else {
      year += 1900;
    }

    month -= 1;
    birtday.setFullYear(year, month, day);
    return birtday;
  }

  public getSexFromPesel(peselNumber: string): string {
      const patchModelValue = {};
      if ((parseInt(peselNumber[9]) % 2) === 0) {
        return "K";
      } else {
        return "M";
      }
      return null;
  }

}
