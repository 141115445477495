import { Directive, Output, Input, HostListener, EventEmitter, ElementRef } from "@angular/core";
import { ACCEPT_ALL_ZONE_NAME } from "./draggable.directive";

export const DRAG_OVER_FLAG = "droppable-over";
const FILES_TYPE_NAME = "Files";

@Directive({
  selector: "[plDragTarget]"
})
export class DragTargetDirective {
  @Input()
  public set plDragTarget(zone: string) {
    if (zone) {
      this._zone = zone;
    } else {
      this._zone = ACCEPT_ALL_ZONE_NAME;
    }
  }

  @Input()
  public set plAcceptFiles(accept: boolean) {
    if (accept) {
      this._acceptFiles = true;
    }
  }

  @Output("elementDropped") public dropped = new EventEmitter<DataTransfer>();

  constructor(private el: ElementRef) { }

  @HostListener("dragenter", ["$event"])
  @HostListener("dragover", ["$event"])
  public onDragOver(event) {
    if (this.isDropAccepted(event.dataTransfer)) {
      this.addDropOverClass();
      event.preventDefault();
    } else {
      this.removeDropOverClass();
    }
  }

  @HostListener("dragleave", ["$event"])
  public onDragLeave() {
    this.removeDropOverClass();
  }

  @HostListener("drop", ["$event"])
  public onDrop(event) {
    this.removeDropOverClass();
    if (!this.isDropAccepted(event.dataTransfer)) {
      return;
    }
    this.dropped.next(event.dataTransfer);
    event.preventDefault();
  }

  private isDropAccepted(dataTransfer: DataTransfer): boolean {
    if (this._acceptFiles && dataTransfer.types.indexOf(FILES_TYPE_NAME) !== -1) {
      return true;
    }

    if (this._zone === ACCEPT_ALL_ZONE_NAME) {
      return true;
    }

    const indexOfZone = dataTransfer.types.indexOf(this._zone);
    if (indexOfZone !== -1) {
      return true;
    }

    return false;
  }

  private addDropOverClass(): void {
    this.el.nativeElement.classList.add(DRAG_OVER_FLAG);
  }

  private removeDropOverClass(): void {
    this.el.nativeElement.classList.remove(DRAG_OVER_FLAG);
  }

  private _zone: string;
  private _acceptFiles = false;
}
