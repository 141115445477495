import {Injectable} from "@angular/core";
import {Resolve} from "@angular/router";
import {User} from "../../models/user.model";
import {ActivatedRouteSnapshot} from "@angular/router";
import {RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {BackendService} from "../../services/backend.service";

@Injectable()
export class UsersProvider implements Resolve<User[]> {
  constructor(private backendService: BackendService) {}
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User[]> {
    return this.backendService.get<User[]>("/api/users");
  }
}
