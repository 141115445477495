import {Component, Inject} from "@angular/core";
import {User} from "../../models/user.model";
import {AccessLevel} from "../../models/user.model";
import {FormGroup, Validator, Validators} from "@angular/forms";
import {FormControl} from "@angular/forms";
import {UsersService} from "../../settings/services/users.service";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../core/logging/auth.service";
import {CurrentUserService} from "../../services/current-user.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ValidatorsHelper} from "../../services/validators.helper";

@Component({
  selector: "user-add",
  templateUrl: "./user-add.component.html",
  styleUrls: ["./user-add.component.scss"]

})
export class UserAddComponent {

  public user: User;
  public passwordConfirm: string;
  public userFormGroup: FormGroup;

  constructor(private userService: UsersService,
              private currentUserService: CurrentUserService,
              private activatedRoute: ActivatedRoute,
              private dialogRef: MatDialogRef<UserAddComponent>,
              @Inject(MAT_DIALOG_DATA) public userData: any,
              private authService: AuthService) {
    this.createFormGroup();
  }

  public updateUser(): void {
    const userModel = this.readModel();
    this.userService.addUser(userModel).subscribe(
      () => {
        this.dialogRef.close();
      },
      error => {
        this.userNameFormControl.setErrors({exists: true});
      }
    );
  }

  public isComponentModified(): boolean {
    return this.userFormGroup.dirty;
  }

  private readModel(): User {
    const userData = this.userFormGroup.getRawValue();
    userData.accessLevel = userData.roles.doctorAccessLevel ? AccessLevel.User : 0;
    if (userData.roles.adminAccessLevel === true) {
      userData.accessLevel += AccessLevel.Admin;
    }
    delete userData.roles;
    return userData;
  }

  private createFormGroup(): void {
    this.userNameFormControl = new FormControl("", Validators.required);
    this.passwordFormControl = new FormControl("", Validators.required);
    this.userFormGroup = new FormGroup({
      "username": this.userNameFormControl,
      "password": this.passwordFormControl,
      "repeatPassword": new FormControl("", [
        Validators.required, ValidatorsHelper.validatePasswordRepeated(this.passwordFormControl)]),
      "email": new FormControl("", [Validators.email, Validators.required]),
      "firstName": new FormControl(),
      "lastName": new FormControl(),
      "nrUprawnienia": new FormControl(),
      "organWydajacyUprawnienie": new FormControl(),
      "roles": new FormGroup({
        "adminAccessLevel": new FormControl(),
        "doctorAccessLevel": new FormControl(true)
      }, ValidatorsHelper.validateUserRoles)
    });
  }


  private userNameFormControl: FormControl;
  private passwordFormControl: FormControl;
}
