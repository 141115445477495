import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {RouterModule} from "@angular/router";
import {PracticeDetailsViewComponent} from "./practice-details-view/practice-details-view.component";
import {PracticeResolver} from "./practice.resolver";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "details",
      },
      {
        path: "details",
        component: PracticeDetailsViewComponent,
        resolve: {
          practice: PracticeResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule
  ],
  exports: [RouterModule]
})
export class PracticeRoutingModule {
}
