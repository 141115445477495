import {NgModule} from "@angular/core";
import {UsersListComponent} from "./users-list/users-list.component";
import {Routes} from "@angular/router";
import {RouterModule} from "@angular/router";
import {UserAddComponent} from "./user-add/user-add.component";
import {UserDetailsProvider} from "./providers/user-details.provider";
import {CurrentUserDetailsComponent} from "./current-user-details/current-user-details.component";
import {CheckForPendingModificationsGuard} from "../active-exam/guards/check-for-pending-modifications.guard";
import {UsersProvider} from "./providers/users.provider";

const routes: Routes = [
  {
    path: "",
    children: [
      {path: "", pathMatch: "full", redirectTo: "list"},
      {
        path: "list",
        component: UsersListComponent,
        resolve: {users: UsersProvider},
      },
      {
        path: "add",
        component: UserAddComponent
      },
      {
        path: "details/my",
        component: CurrentUserDetailsComponent,
        resolve: {userData: UserDetailsProvider},
        canDeactivate: [CheckForPendingModificationsGuard]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class UsersRoutingModule {
}
