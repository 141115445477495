
import {tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";

import {NgFullLoaderService} from "./loader.service";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: NgFullLoaderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req).pipe(
      tap(event => {
          if (!this.loaderService.IsHttpEnabled) {
            return;
          }

          if (event instanceof HttpResponse) {
            this.loaderService.hideLoader();
          } else {
            this.loaderService.showLoader();
          }
        },
        err => {
          this.loaderService.hideLoader();
        }
      ),
        catchError((error: HttpErrorResponse) => {
          this.loaderService.hideLoader();
          return throwError(error);
        }));
  }
}
