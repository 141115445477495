import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LoginModel} from "./models/LoginModel";
import {TokenModel} from "./models/TokenModel";
import {Router} from "@angular/router";
import {AuthHelperAbstract} from "./auth-helper.abstract";
import {Subject} from "rxjs";
import {User} from "./models/user.model";
import {BackendService} from "../../services/backend.service";

@Injectable()
export class AuthService extends AuthHelperAbstract {

  public $tokenFetched: Subject<boolean> = new Subject<boolean>();

  public currentUser: User = new User();

  public get isLogged(): boolean {
    return this._isLogged;
  }

  public get authToken(): string {
    return this._authToken;
  }

  private _isLogged = false;
  private _authToken: string;
  private _refreshToken: string;

  constructor(private http: HttpClient, private backendService: BackendService, private router: Router) {
    super();
    this.tryGetTokenFromStorage();
  }

  public logIn(login: LoginModel) {
    this.fetchToken(login);
  }

  public logout() {
    this.router.navigate(["/logging"]);
    this.cleanToken();
  }

  public processRefreshToken() {
    this.fetchToken();
  }

  public hasRole(role: string): boolean {
    const tokenData = this.parseJwt(this._authToken);
    return tokenData.authorities.findIndex((item: string) => item === role) !== -1;
  }

  private fetchToken(login?: LoginModel) {
    const tokenUrlForm = login == null ? this.getRefreshTokenUrl(this._refreshToken) : this.getTokenUrl(login);
    this.http.post<TokenModel>(this.url, tokenUrlForm, {headers: this.getHeaders()}).subscribe(
      token => {
        this.handleToken(token);
      },
      err => {
        this.$tokenFetched.next(false);
      }
    );
  }

  private updateCurrentUser(token: string): void {
    if (token == null) {
      this.currentUser = new User();
      return;
    }

    const tokenData = this.parseJwt(token);
    const user = new User();
    user.userName = tokenData.user_name;
    user.firstName = tokenData.firstName;
    user.lastName = tokenData.lastName;
    user.isAdmin = this.hasRole("ROLE_ADMIN");
    user.isUser = this.hasRole("ROLE_USER");
    user.authorities = tokenData.authorities;
    this.currentUser = user;
  }

  private tryGetTokenFromStorage(): void {
    const sessionObject = sessionStorage.getItem(this.sessionStorageKey);
    if (sessionObject !== null) {
      const token: TokenModel = JSON.parse(sessionObject);
      if (!token.isExpired) {
        this.handleToken(token);
      }
    }
  }

  private cleanToken(): void {
    sessionStorage.removeItem(this.sessionStorageKey);
    this._isLogged = false;
    this._authToken = this._refreshToken = null;
    this.updateCurrentUser(null);
  }

  private handleToken(token: TokenModel) {
    this._authToken = token.access_token;
    this._refreshToken = token.refresh_token;
    if (token.createDate == null) {
      sessionStorage.removeItem(this.sessionStorageKey);
      token.createDate = new Date();
      sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(token));
    }
    this.updateCurrentUser(token.access_token);
    this.$tokenFetched.next(true);
    this._isLogged = true;
  }

  private parseJwt(token): any {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }
}
