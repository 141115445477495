import {Injectable} from "@angular/core";
import {BackendService} from "../../services/backend.service";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {MessageBubbleService} from "../../message-bubble/services/message-bubble.service";

@Injectable({providedIn: "root"})
export class PasswordService {
  constructor(private http: BackendService, private bubbleService: MessageBubbleService) {}

  public resetPassword(username: string): Observable<any> {
    return this.http.post("/api/password/reset/" + username, null)
      .pipe(
        catchError(error => this.handlePasswordError(error))
      );
  }

  private handlePasswordError(error): Observable<any> {
    this.bubbleService.openError("Nie udało się restartowanie hasła.")
    return throwError("Problem with reseting the password.");
  }
}
