import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AppMainComponent} from "./app-main.component";
import {IsLoggedCanActivateGuard} from "./core/logging/is-logged-can-activate.guard";
import {AppMenuViewComponent} from "./app-menu-view/app-menu-view.component";

const routes: Routes = [
  {path: "logging", loadChildren: "app/core/logging/logging.module#LoggingModule"},
  {
    path: "", component: AppMainComponent, canActivate: [IsLoggedCanActivateGuard], children: [
      {path: "settings", loadChildren: "app/settings/settings.module#SettingsModule"},
      {
        path: "", component: AppMenuViewComponent, children: [
          {path: "practices", loadChildren: "app/practice-list/practice-list.module#PracticeListModule"},
          {path: "active", loadChildren: "app/active-exam/active-exam.module#ActiveExamModule"},
          {path: "exams", loadChildren: "app/exam-list/exam-list.module#ExamListModule"},
          {path: "billing", loadChildren: "app/billing/billing.module#BillingModule"},
          {path: "users", loadChildren: "app/users/users.module#UsersModule"}
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
