import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { IConfirmDialogData, ConfirmDialogResult, ConfirmDialogType } from "../services/confirm-dialog.model";

@Component({
  selector: "confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.css"]
})
export class ConfirmDialogComponent {
  public readonly NoResult = ConfirmDialogResult.no;
  public readonly YesResult = ConfirmDialogResult.yes;
  public readonly CancelResult = ConfirmDialogResult.cancel;
  public readonly NoYesType = ConfirmDialogType.NoYes;
  public readonly YesNoCancelType = ConfirmDialogType.YesNoCancel;
  public readonly OkType = ConfirmDialogType.Ok;

  public dialogType: ConfirmDialogType = ConfirmDialogType.NoYes;

  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData) {
    if (data.dialogType != null) {
      this.dialogType = data.dialogType;
    }
  }
}
