import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {IPractice} from "../models/practice.model";
import {BackendService} from "./backend.service";

@Injectable()
export class CurrentPracticeService {

  constructor(private backendService: BackendService) {
  }

  public getCurrentPractice(): Observable<IPractice> {
    return this.backendService.get<IPractice>("/api/practice/current");
  }
}
