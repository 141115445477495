import {Injectable} from "@angular/core";
import {ExamId} from "../models/exam-id.model";
import {ExamGuidType} from "../models/exam-id.model";
import {BackendService} from "./backend.service";
import {Observable} from "rxjs";
import {ExamTypeModel} from "../models/exam-type.model";
import {map} from "rxjs/operators";
import {ExamCardModel} from "../models/exam-card.model";
import {IDecisionModel} from "../models/decision.model";
import {Exam} from "../models/exam.model";
import {ExamIdType} from "../models/exam.model";

export const EXAM_ID_PARAM_NAME = "examId";

@Injectable()
export class ExamService {

  constructor(private http: BackendService) {
  }

  public removeExam(examId: ExamIdType): Observable<any> {
    return this.http.delete("/api/exam/" + examId);
  }

  public getExamExpireDate(exam: Exam): Observable<Exam> {
    return this.http.post<Exam>("/api/utils/examExpireDate", exam);
  }

  public getExamCardById(examId: number): Observable<ExamCardModel> {
    return this.http.get<ExamCardModel>("/api/examCard/" + examId);
  }

  public getExamPageNr(examId: number, pageSize: number): Observable<number> {
    const params = {};
    params["size"] = pageSize;
    return this.http.get<number>("/api/examCard/" + examId + "/pageNr", {params: params});
  }

  public getExamCards(filter: string = null, page: number = null, size: number = null): Observable<ExamCardModel[]> {
    const params = {};
    if (filter != null) {
      params["filter"] = filter;
    }
    if (page != null && size != null) {
      params["page"] = page;
      params["size"] = size;
    }
    return this.http.get("/api/examCard", {params: params});
  }

  public addDecision(decision: IDecisionModel): Observable<IDecisionModel> {
    return this.http.post("/api/examCard/decision/" + decision.examId, decision);
  }

  public removeDecision(decision: IDecisionModel): Observable<void> {
    return this.http.delete("/api/examCard/decision/" + decision.examId + "/" + decision.examTypeId);
  }

  public getExamDecisionFreeNr(): Observable<IDecisionModel> {
    return this.http.get("/api/examCard/decision/nextFreeNr");
  }

  public getExamTypeData(examId: ExamGuidType): Observable<ExamTypeModel> {
    return this.http.get("/api/examtype/" + examId);
  }

  public updateExamType(examType: ExamTypeModel): Observable<any> {
    return this.http.patch("/api/examtype/" + examType.id, examType);
  }

  public getExamCardsCount(): Observable<number> {
    return this.http.get("/api/examCard/count");
  }

  public getPossibleExamIds(): Observable<ExamId[]> {
    return this.http.get<ExamTypeModel[]>("/api/examtype").pipe(map((data) => {
      return this.mapExamTypeCollectionToExamIdCollection(data);
    }));
  }

  public getPossibleExams(): Observable<ExamTypeModel[]> {
    return this.http.get("/api/examtype");
  }

  private mapExamTypeCollectionToExamIdCollection(examTypes: ExamTypeModel[]): ExamId[] {
    const returnCollection = [];
    for (const exam of examTypes) {
      returnCollection.push(this.mapExamTypeToExamId(exam, null));
      if (exam.abstractType) {
        for (const subExamType of exam.subTypes) {
          returnCollection.push(this.mapExamTypeToExamId(subExamType, exam));
        }
      }
    }
    return returnCollection;
  }

  private mapExamTypeToExamId(examType: ExamTypeModel, parentExamType: ExamTypeModel): ExamId {
    const examId = new ExamId();
    examId.name = examType.name;
    examId.guid = examType.id;
    examId.isAbstract = examType.abstractType;
    if (parentExamType != null) {
      examId.parentName = parentExamType.name;
      examId.parentId = parentExamType.id;
    }
    return examId;
  }
}
