import {Injectable} from "@angular/core";
import {User} from "../../models/user.model";
import {Observable} from "rxjs";
import {BackendService} from "../../services/backend.service";
import {AuthService} from "../../core/logging/auth.service";

@Injectable()
export class UsersService {

  constructor(private http: BackendService, private authService: AuthService) {
  }

  public changeUserPassword(token: string, userData: User): Observable<User> {
    return this.http.post("/api/password/change/" + token, userData);
  }

  public addUser(user: User): Observable<User> {
    return this.http.post<User>("api/user", user);
  }

  public addAdminForPractice(user: User, practiceName: string): Observable<void> {
    return this.http.post("/api/user/admin/" + encodeURI(practiceName), user);
  }

  public updateUser(user: User, currentUser = false): Observable<User> {
    let serverResourceName = "api/user/";
    if (currentUser) {
      serverResourceName += "my";
    } else {
      serverResourceName += encodeURIComponent(user.username);
    }

    return this.http.patch<User>(serverResourceName, user);
  }
}
