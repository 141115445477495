import { Injectable } from "@angular/core";
import { BackendService, HttpOptions } from "./backend.service";
import { ConfigurationService } from "./configuration.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class LizardBackendService {

  constructor(private httpClient: HttpClient, private configurationSrv: ConfigurationService) {
  }

  public post<T>(url: string, body: any | null, options?: HttpOptions): Observable<T> {
    return this.httpClient.post<T>(this.generateUrl(url), body, options);
  }

  public put<T>(url: string, body: any | null, options?: HttpOptions): Observable<T> {
    return this.httpClient.put<T>(this.generateUrl(url), body, options);
  }


  public patch<T>(url: string, body: any | null, options?: HttpOptions): Observable<T> {
    return this.httpClient.patch<T>(this.generateUrl(url), body, options);
  }

  public get<T>(url: string, options?: HttpOptions): Observable<T> {
    return this.httpClient.get<T>(this.generateUrl(url), options);
  }

  public delete<T>(url: string, options?: HttpOptions): Observable<T> {
    return this.httpClient.delete<T>(this.generateUrl(url), options);
  }

  protected generateUrl(serviceName: string): string {
    if (!serviceName.startsWith("/")) {
      serviceName = "/" + serviceName;
    }
    return this.configurationSrv.getLizardServerUrl() + serviceName;
  }
}
