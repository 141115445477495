import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {User} from "../../models/user.model";
import {CurrentUserDetailsComponent} from "../current-user-details/current-user-details.component";
import {MatDialog} from "@angular/material";

@Component({
  selector: "users-list-item",
  templateUrl: "./users-list-item.component.html",
  styleUrls: ["./users-list-item.component.scss"]
})
export class UsersListItemComponent {

  @Output() public dataUpdateNeeded = new EventEmitter<void>();
  @Input() public userData: User;
  constructor(private dialog: MatDialog) { }

  public click(): void {
    this.dialog.open(CurrentUserDetailsComponent, {data: {username: this.userData.username}})
      .afterClosed().subscribe(() => this.dataUpdateNeeded.emit());
  }
}
