import {Injectable} from "@angular/core";
import {HttpInterceptor} from "@angular/common/http";
import {HttpHandler} from "@angular/common/http";
import {HttpEvent} from "@angular/common/http";
import {HttpRequest} from "@angular/common/http";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageBubbleService} from "../message-bubble/services/message-bubble.service";
import {Observable} from "rxjs";
import {throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ConfigurationService} from "./configuration.service";

@Injectable({
  providedIn: "root"
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private bubbleService: MessageBubbleService, private config: ConfigurationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (req.url.indexOf(this.config.getLizardServerUrl()) !== -1) {
            return;
          }

          let errorMessage = "Przepraszamy ale wystąpił nieznany błąd.";
          // if (error.error instanceof ErrorEvent) {
          //   // client-side error
          //   errorMessage = `Error: ${error.error.message}`;
          // } else {
            // server-side error
            switch (error.status) {
              case 500:
              errorMessage = "Nie udało się zrealizować wybranej operacji. (E0500)";
              break;
              case 403:
                errorMessage = "Użytkownik nie ma uprawnień do wykonania tej operacji.";
                break;
              case 404:
                errorMessage = "Konfiguracja serwera jest nie kompletna. Skontaktuj się z administratorem.";
            }
//          }
          this.bubbleService.openError(errorMessage);
          return throwError(errorMessage);
        })
      );
  }
}
