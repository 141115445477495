import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";

import {NgFullLoaderModule} from "./core/loader/loader.module";

import {AppComponent} from "./app.component";
import {HeaderPanelComponent} from "./header-panel/header-panel.component";
import {MatSidenavModule} from "@angular/material";
import {MAT_DATE_FORMATS} from "@angular/material";
import {MAT_DATE_LOCALE} from "@angular/material";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PatientNameService} from "./services/patient-name.service";
import {ExamService} from "./services/exam.service";
import {AppRoutingModule} from "./app-routing.module";
import {ClosedExamsService} from "./services/closed-exams.service";
import {SideNavigationModule} from "./side-navigation/side-navigation.module";
import {AppMainComponent} from "./app-main.component";
import {HttpClientModule} from "@angular/common/http";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {BackendService} from "./services/backend.service";
import {UsersService} from "./settings/services/users.service";
import {ConfigurationService} from "./services/configuration.service";
import {ExamResolver} from "./services/exam.resolver";
import {KnownPatientsService} from "./services/known-patients.service";
import {LizardBackendService} from "./services/lizard-backend.service";
import {ExamTypesResolver} from "./services/exam-types.resolver";
import {ConfirmDialogService} from "./shared/services/confirm-dialog.service";
import {ActiveExamsService} from "./active-exam/services/active-exams.service";
import {MatMenuModule} from "@angular/material/menu";
import {MatDividerModule} from "@angular/material/divider";
import {AppMenuViewComponent} from "./app-menu-view/app-menu-view.component";
import {PracticeModule} from "./practice/practice.module";
import {CurrentPracticeService} from "./services/current-practice.service";
import {MomentDateModule} from "@angular/material-moment-adapter";
import {HttpErrorInterceptor} from "./services/http-error.interceptor";
import {UsersModule} from "./users/users.module";
import {CurrentUserService} from "./services/current-user.service";
import {PeselService} from "./services/pesel.service";
import {SharedModule} from "./shared/shared.module";
import {IsLoggedCanActivateGuard} from "./core/logging/is-logged-can-activate.guard";
import {AuthService} from "./core/logging/auth.service";
import {AuthInterceptor} from "./core/logging/auth.interceptor";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderPanelComponent,
    AppMainComponent,
    AppMenuViewComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    AppRoutingModule,
    SideNavigationModule,
    NgFullLoaderModule.forRoot(),
    MatMenuModule,
    MatDividerModule,
    PracticeModule,
    MomentDateModule,
    UsersModule
  ],
  providers: [
    ConfigurationService,
    BackendService,
    LizardBackendService,
    UsersService,
    PatientNameService,
    ExamService,
    ActiveExamsService,
    ClosedExamsService,
    ExamResolver,
    KnownPatientsService,
    ExamTypesResolver,
    ConfirmDialogService,
    CurrentPracticeService,
    CurrentUserService,
    PeselService,
    IsLoggedCanActivateGuard,
    AuthService,
    {provide: MAT_DATE_LOCALE, useValue: "pl-PL"},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
