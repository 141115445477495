
export type ExamGuidType = number;

export class ExamId {
    name: string;
    guid: ExamGuidType;
    isAbstract: boolean;
    parentName: string;
    parentId: number;
}
