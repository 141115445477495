import {Resolve} from "@angular/router";
import {RouterStateSnapshot} from "@angular/router";
import {ActivatedRouteSnapshot} from "@angular/router";
import {IPractice} from "../models/practice.model";
import {CurrentPracticeService} from "../services/current-practice.service";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class PracticeResolver implements Resolve<IPractice> {
  constructor(private currentPracticeService: CurrentPracticeService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPractice> {
    return this.currentPracticeService.getCurrentPractice();
  }
}
