
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../core/logging/auth.service";
import { User } from "../core/logging/models/user.model";
import {MatDialog} from "@angular/material";
import {PracticeDetailsViewComponent} from "../practice/practice-details-view/practice-details-view.component";
import {CurrentUserDetailsComponent} from "../users/current-user-details/current-user-details.component";

@Component({
  selector: "header-panel",
  templateUrl: "./header-panel.component.html",
  styleUrls: ["./header-panel.component.scss"]
})
export class HeaderPanelComponent implements OnInit {
  public currentUser: User;
  constructor(private authService: AuthService, private dialog: MatDialog) { }

  public ngOnInit() {
    this.currentUser = this.authService.currentUser;
  }

  public logOut() {
    this.authService.logout();
  }

  public getFullUserName(): string {
    if (this.currentUser == null) {
      return "";
    }

    return this.currentUser.firstName + " " + this.currentUser.lastName;
  }

  public openCurrentUserDetails(): void {
    this.dialog.open(CurrentUserDetailsComponent, {data: {username: "my"}});
  }

  public openPracticeDetails(): void {
    this.dialog.open(PracticeDetailsViewComponent, {});
  }
}
