import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {of} from "rxjs";
import {ActiveExamDetalesPanelComponent} from "../active-exam-detales-panel/active-exam-detales-panel.component";
import {ActiveExamsService} from "../services/active-exams.service";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from "@angular/router";
import {ModificationCheckService} from "../../exam/service/modification-check.service";
import {catchError} from "rxjs/operators";
import {switchMap} from "rxjs/operators";

@Injectable()
export class CheckForPendingModificationsGuard implements CanDeactivate<ActiveExamDetalesPanelComponent> {
  constructor(private modificationCheckService: ModificationCheckService,
              private activeExamService: ActiveExamsService) {
  }

  public canDeactivate(component: ActiveExamDetalesPanelComponent, currentRoute: ActivatedRouteSnapshot,
                       currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean> {
    if (!this.modificationCheckService.isComponentModified()) {
      return true;
    }

    this.modificationCheckService.updateModel();
    return this.activeExamService.saveActiveExam(component.selectedActiveExam)
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(true)));
  }
}
