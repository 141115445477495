import { Component, OnInit } from "@angular/core";
import {NavigationItem} from "../side-navigation/models/navigation-item.model";

@Component({
  selector: "app-menu-view",
  templateUrl: "./app-menu-view.component.html",
  styleUrls: ["./app-menu-view.component.scss"]
})
export class AppMenuViewComponent {
  public items: NavigationItem[] = [
    {
      name: "Aktywne badania",
      route: "/active",
      roles: "ROLE_USER"
    },
    {
      name: "Badania",
      route: "/exams",
      roles: "ROLE_USER"
    },
    // {
    //   name: "Rachunki",
    //   route: "/billing"
    // },
    {
      name: "Użytkownicy",
      route: "/users",
      roles: "ROLE_ADMIN"
    },
    {
      name: "Praktyki",
      route: "/practices",
      roles: "ROLE_SUPER"
    }
  ];
}
